import { Link } from "gatsby"
import React from "react"
import VisaLogo from "../../static/images/Visa_Inc_logo.svg"

const Footer = () => {
  return (
    <footer className="d-flex px-0 px-md-14 py-4 bg-dark flex-column flex-lg-row">
      <div className="footer-text fs-sm text-muted px-6 p-md-6 text-center text-md-left order-2 order-lg-1 d-flex flex-column justify-content-center">
        <p className="mb-3">
          At Card Noir we believe that simple financial management is key to any
          individual and business owner. That’s why we have designed a range of
          innovative payment card products, designed to help you manage your
          daily financial life.
        </p>
        <p className="mb-3">
          Deposits made on the card are not covered by any deposit insurance.
          Visa and the Visa Brand Mark are registered trademarks of Visa Int
          Incorporated.
        </p>
        <div className="mb-3 d-flex flex-row">
          <div style={{ lineHeight: "24px" }}>
            This card is issued pursuant to agency agreements with licensed
            actors.
          </div>
          <div style={{ lineHeight: "24px" }}>
            <VisaLogo style={{ height: "24px" }} />
          </div>
        </div>
        <ul>
          <li>&copy; Copyright Card Noir Ltd 2021 </li>
          <li>
            <a href="#footer">Help</a>
          </li>
          <li>
            <a href="#footer">About us</a>
          </li>
          <li>
            <Link to="/terms/" activeClassName="activeItem">
              Terms and conditions
            </Link>
          </li>
          <li>
            <a href="#footer">Privacy policy</a>
          </li>
        </ul>
      </div>
      <div className="order-1 order-lg-2 mx-auto footer-card-wrapper">
        <img
          src="/images/CN_card_Visa2_resized_footer-min.png"
          loading="lazy"
          alt=""
          className="card-holder footer-card"
        />
      </div>
    </footer>
  )
}

export default Footer
